import React, { Ref, useState } from 'react';
import {
  Text,
  Flex,
  Spacer,
  IconButton,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
  Img,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { FaAlignJustify } from 'react-icons/fa';
import { Icon } from '@chakra-ui/react';
import icon from '../assets/logo192.png'

type NavProps = {
  onOpen: () => void;
  ref: Ref<any>;
}

const Nav = ({ onOpen, ref }: NavProps) => {
  const [scroll, setScroll] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const navBg = useColorModeValue('white', 'blackAlpha.200');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const changeScroll = () =>
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
      ? setScroll(true)
      : setScroll(false);

  window.addEventListener('scroll', changeScroll);

  return (
    <Flex
      h="10vh"
      alignItems="center"
      p="6"
      boxShadow={scroll ? 'base' : 'none'}
      position="sticky"
      top="0"
      zIndex="sticky"
      w="full"
      bg={navBg}
    >
      <Text fontSize="xl" fontWeight="bold">
        <Img src={icon} alt="Chakra UI" />
        Servicios para Bodas
      </Text>

      <Spacer />

      <Flex alignItems="center">
        <IconButton mr="10" w={6} h={6} p={5} onClick={toggleColorMode} aria-label="switch theme">
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </IconButton>

        {isLargerThanMD ? (
          <>
            <a target="_blank" rel="noreferrer" href="https://appseed.us/apps/react/">
              More Apps
            </a>
          </>
        ) : (
          <IconButton ref={ref} onClick={onOpen} aria-label="Align/justify">
            <Icon as={FaAlignJustify} />
          </IconButton>
        )}

      </Flex>
    </Flex>
  );
};

export default Nav;